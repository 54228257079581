import { Card, Heading, Stack, TextStyle } from '@segunosoftware/equinox';
import { Button, DataTable, Pagination, Tooltip } from '@shopify/polaris';
import { format } from 'date-fns';
import { useState } from 'react';
import { useScheduledNewsletters } from '../../hooks/marketingplatform/useNewsletters';
import SlimPolarisTableButtons from '../SlimPolarisTableButtons';
import { truncateText } from './Automations';
import NewsletterPreviewAction from './NewsletterPreviewAction';
import TargetRecipientsDescription from './TargetRecipientsDescription';

export type ScheduledNewslettersProps = {
	accountId: number;
};

export default function ScheduledNewsletters({ accountId }: ScheduledNewslettersProps) {
	const { scheduledNewsletters, refetch, isLoading, hasNextPage, fetchNextPage } = useScheduledNewsletters(accountId);
	const [currentPage, setCurrentPage] = useState(0);
	const currentNewsletters = scheduledNewsletters[currentPage] ?? [];

	return (
		<Card
			title={
				<Stack alignment="center">
					<Stack.Item fill>
						<Heading>Scheduled newsletters</Heading>
					</Stack.Item>
					<Button onClick={refetch} loading={isLoading} variant="plain">
						Refresh
					</Button>
					<Pagination
						hasPrevious={currentPage > 0}
						onPrevious={() => setCurrentPage(currentPage - 1)}
						hasNext={hasNextPage || currentPage !== scheduledNewsletters.length - 1}
						onNext={() => {
							const nextPage = currentPage + 1;
							setCurrentPage(nextPage);
							if (nextPage === scheduledNewsletters.length) {
								fetchNextPage();
							}
						}}
					/>
				</Stack>
			}
			sectioned>
			<SlimPolarisTableButtons>
				<DataTable
					verticalAlign="middle"
					columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
					headings={['Scheduled at', 'Name', 'Status', 'Recipients', 'Discount', 'Actions']}
					rows={currentNewsletters.map(newsletter => {
						const { name, campaignStatus, scheduledAt, discountCodeType, discountCode, targetRecipients } = newsletter;
						return [
							<Stack spacing="extraTight">
								{scheduledAt && (
									<Tooltip content={format(scheduledAt, 'eeee, MMMM do yyyy, h:mm:ss a')}>
										<TextStyle>{format(scheduledAt, "yyyy-MM-dd 'at' h:mm a")}</TextStyle>
									</Tooltip>
								)}
							</Stack>,
							<Tooltip content={name}>
								{targetRecipients === 'INHERITED_NON_OPENS' ? (
									<TextStyle variation="strong">{truncateText(name)}</TextStyle>
								) : (
									truncateText(name)
								)}
							</Tooltip>,
							campaignStatus,
							<TargetRecipientsDescription accountId={accountId} newsletter={newsletter} />,
							discountCode ? (
								<Tooltip content={discountCode}>
									<p>{discountCodeType}</p>
								</Tooltip>
							) : (
								<p>{discountCodeType}</p>
							),
							<Stack alignment="center" wrap={false}>
								<NewsletterPreviewAction accountId={accountId} newsletterId={newsletter.id} />
							</Stack>
						];
					})}
					hideScrollIndicator
					truncate
					fixedFirstColumns={2}
				/>
			</SlimPolarisTableButtons>
		</Card>
	);
}
