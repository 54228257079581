import { Card, FormatMoney, FormatNumber, FormatPercent, NumberedLabel, Stack, TimeFromNowLabel } from '@segunosoftware/equinox';
import { Badge, Button, DataTable, Icon, Modal, Popover, Tag, Tooltip } from '@shopify/polaris';
import { ArrowRightIcon, ChartVerticalIcon, DiscountCodeIcon, EmailIcon, ExportIcon, ProductRemoveIcon } from '@shopify/polaris-icons';
import { format, formatDuration } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useAutomations } from '../../hooks/marketingplatform/useAutomations';
import { useMarketingPlatformAccount } from '../../hooks/marketingplatform/useMarketingPlatformAccount';
import { useMarketingPlatformAccountActions } from '../../hooks/marketingplatform/useMarketingPlatformAccountActions';
import ConversionFormatMoney from '../ConversionFormatMoney';
import SlimPolarisTableButtons from '../SlimPolarisTableButtons';
import StatusIcon from '../StatusIcon';
import DiscountSummary from './DiscountSummary';
import ProgramMessagePreviewAction from './ProgramMessagePreviewAction';
import { getSparkPostActivityUrl } from './RecentNewsletters';

const SUMMARY_STATS = {
		uniqueDeliveries: { colIndex: 0 },
		uniqueOpens: { colIndex: 1 },
		uniqueClicks: { colIndex: 2 },
		uniqueBounces: { colIndex: 3 },
		uniqueSpamComplaints: { colIndex: 4 },
		uniqueUnsubscribes: { colIndex: 5 }
	},
	CUSTOM_TYPES = ['TAG_TRIGGER', 'POST_PURCHASE', 'ABANDONMENT', 'BACK_IN_STOCK', 'SEND_MARKETING_EMAIL'],
	OVERFLOW_LIMIT = 20;
const NODE_TYPES = Object.freeze({
	TRIGGER_DELAY: { value: 'TRIGGER_DELAY', label: 'Trigger delay' },
	DELAY: { value: 'DELAY', label: 'Delay' },
	MESSAGE: { value: 'MESSAGE', label: 'Message' }
});

export function truncateText(text) {
	return text.length > OVERFLOW_LIMIT ? text.substring(0, OVERFLOW_LIMIT - 1).concat('...') : text;
}

Automations.propTypes = {
	accountId: PropTypes.string.isRequired
};

export default function Automations({ accountId }) {
	const { automations = [], onLoad, isLoading, automation, onLoadMessages, isLoadingMessages } = useAutomations(accountId);
	const [activityToExport, setActivityToExport] = useState(null);
	const { account } = useMarketingPlatformAccount(accountId);
	const { exportEmailActivity, isExportingEmailActivity } = useMarketingPlatformAccountActions(accountId);
	const [selectedAutomation, setSelectedAutomation] = useState(null);
	const [showExclusionTagModal, setShowExclusionTagModal] = useState(false);
	const [showMessageNodeModal, setShowMessageNodeModal] = useState(false);
	const [popoverVisible, setPopoverVisible] = useState(false);

	useEffect(() => {
		if (activityToExport) {
			exportEmailActivity(activityToExport);
		}
	}, [activityToExport, exportEmailActivity]);

	useEffect(() => {
		if (!isExportingEmailActivity) {
			setActivityToExport(null);
		}
	}, [isExportingEmailActivity]);

	useEffect(() => {
		if (automation != null) {
			setSelectedAutomation(automation);
		}
	}, [automation]);

	function onHideExclusionTagModal() {
		setShowExclusionTagModal(false);
	}

	function onShowExclusionTagModal(automation) {
		setSelectedAutomation(automation);
		setShowExclusionTagModal(true);
	}

	function onHideMessageNodeModal() {
		setShowMessageNodeModal(false);
	}

	function onShowMessageNodeModal(automation) {
		setSelectedAutomation(automation);
		onLoadMessages(automation.id);
		setShowMessageNodeModal(true);
	}

	function getSummaryStatKeys() {
		return Object.keys(SUMMARY_STATS);
	}

	function formatStat(stat, sends) {
		const value = sends === 0 ? 0 : (stat / sends) * 100.0;
		return (
			<span>
				<FormatNumber value={stat} /> (<FormatPercent value={value} decimals={2} />)
			</span>
		);
	}

	function getConversionTotal() {
		const total = automations.reduce(
			(acc, curr) => {
				acc.conversions += curr.sendSummary.conversionSummary.conversions;
				acc.totalRevenue += curr.sendSummary.conversionSummary.totalRevenue;
				return acc;
			},
			{ conversions: 0, totalRevenue: 0 }
		);
		return renderConversion(total.conversions, total.totalRevenue);
	}

	function renderConversion(conversions, totalRevenue) {
		return (
			<Tooltip content={<NumberedLabel value={conversions} singular="order" />}>
				<ConversionFormatMoney amount={totalRevenue} code={account?.currency} />
			</Tooltip>
		);
	}

	function getStatTotals() {
		const initialValue = {
			sends: 0,
			sendSummary: getSummaryStatKeys().reduce((acc, type) => {
				acc[type] = 0;
				return acc;
			}, {})
		};
		const { sends, sendSummary } = automations.reduce((acc, { sends, sendSummary }) => {
			acc.sends += sends;
			getSummaryStatKeys().forEach(type => {
				acc.sendSummary[type] += sendSummary[type];
			});
			return acc;
		}, initialValue);
		return formatProgramSendSummary(sends, sendSummary);
	}

	function formatProgramSendSummary(sends, sendSummary) {
		const rows = getSummaryStatKeys().reduce((acc, type) => {
			const value = sendSummary[type];
			const { colIndex } = SUMMARY_STATS[type];
			acc.splice(
				colIndex,
				0,
				formatStat(value, ['uniqueDeliveries', 'uniqueBounces'].includes(type) ? sends : sendSummary.uniqueDeliveries)
			);
			return acc;
		}, []);
		return [<FormatNumber value={sends} />, ...rows];
	}

	function getAutomationStatus(automation) {
		const { enabled, triggerEnabled, enabledChangedAt } = automation;
		if (!enabledChangedAt) {
			return 'disabled';
		} else if (!enabled) {
			return 'paused';
		} else if (!triggerEnabled) {
			return 'closed';
		}
		return 'successful';
	}

	function getStatusTooltipLabel(status) {
		if (status === 'paused' || status === 'closed') {
			return status;
		} else if (status === 'disabled') {
			return 'inactive';
		}
		return 'active';
	}

	function getMessageDiscountSummary(message) {
		const { id, priceRule, discountCode, discountCodeType, discountEligibility } = message;
		return (
			<Popover
				active={id === popoverVisible}
				activator={<Button onClick={() => setPopoverVisible(id)} icon={<Icon source={DiscountCodeIcon} color="base" />} variant="plain" />}
				onClose={() => setPopoverVisible(null)}
				sectioned>
				<DiscountSummary
					currency={account.currency}
					priceRule={priceRule}
					discountCode={discountCode}
					discountCodeType={discountCodeType}
					discountEligibility={discountEligibility}
					resourceName="message"
				/>
			</Popover>
		);
	}

	function renderMessageNodes() {
		return selectedAutomation?.nodes?.map((node, i) => {
			const { type, delay, messageId } = node;
			const isMessage = type === NODE_TYPES.MESSAGE.value;
			const lastNode = i === selectedAutomation.nodes.length - 1;

			let nodeContext = delay === 0 ? <p>No delay</p> : <TimeFromNowLabel durationInMinutes={Number(delay)} />;
			if (isMessage) {
				const message = selectedAutomation.messages.filter(m => m.id === messageId)[0];
				nodeContext = (
					<Stack spacing="tight">
						<FormatMoney amount={message.revenue} />
						{message.priceRule && getMessageDiscountSummary(message)}
						<ProgramMessagePreviewAction accountId={accountId} programId={selectedAutomation?.id} programMessageId={message.id} />
					</Stack>
				);
			}
			return (
				<Stack key={i} spacing="tight" distribution="fillEvenly">
					<Stack vertical spacing="extraTight" alignment="center">
						<Badge tone={isMessage ? 'success' : null}>{NODE_TYPES[type].label}</Badge>
						{nodeContext}
					</Stack>
					{!lastNode && <Icon source={ArrowRightIcon} color="base" />}
				</Stack>
			);
		});
	}

	return (
		<Card title="Automations" actions={[{ content: 'Refresh', onAction: onLoad, loading: isLoading }]} sectioned>
			<SlimPolarisTableButtons>
				<DataTable
					verticalAlign="baseline"
					columnContentTypes={[
						'text',
						'text',
						'numeric',
						'numeric',
						'numeric',
						'numeric',
						'numeric',
						'numeric',
						'numeric',
						'numeric',
						'text',
						'text',
						'text'
					]}
					headings={[
						'Status',
						'Name',
						'Conversions',
						'Sends',
						'Deliveries',
						'Opens',
						'Clicks',
						'Bounces',
						'Complaints',
						'Unsubscribes',
						'Trigger Delay',
						'Transactional',
						'Actions'
					]}
					rows={automations
						.sort((a, b) => a.name > b.name)
						.map(automation => {
							const {
								id,
								name,
								type,
								enabledChangedAt,
								enabledMessages,
								sends,
								sendSummary,
								exclusionTags,
								triggerDelay,
								sendFirstMessageTransactionally
							} = automation;
							const typeText = CUSTOM_TYPES.indexOf(type) !== -1 ? ' - ' + type : '';
							const status = getAutomationStatus(automation);
							return [
								<Stack wrap={false}>
									<Tooltip content={getStatusTooltipLabel(status)}>
										<StatusIcon status={status} />
									</Tooltip>
									<p>{enabledMessages}</p>
								</Stack>,
								<Tooltip
									content={`${name + typeText} - ${
										enabledChangedAt
											? `status last changed ${format(enabledChangedAt, 'eeee, MMMM do yyyy, h:mm:ss a')}`
											: 'has never been enabled'
									}`}>
									<p>{truncateText(name)}</p>
								</Tooltip>,
								renderConversion(sendSummary.conversionSummary.conversions, sendSummary.conversionSummary.totalRevenue),
								...formatProgramSendSummary(sends, sendSummary),
								triggerDelay > 0 ? formatDuration({ minutes: triggerDelay }) : '',
								sendFirstMessageTransactionally && (
									<Tooltip content="First message is sent transactionally">
										<p>yes</p>
									</Tooltip>
								),
								<Stack wrap={false}>
									<Tooltip content="Export activity">
										<Button
											icon={ExportIcon}
											onClick={() =>
												setActivityToExport({
													eventId: id,
													isCampaign: false,
													name: name.substring(0, 15)
												})
											}
											loading={isExportingEmailActivity && activityToExport?.eventId === id}
											variant="plain"
										/>
									</Tooltip>
									{account && (
										<Tooltip content="SparkPost analytics">
											<Button url={getSparkPostActivityUrl(account, automation)} icon={ChartVerticalIcon} variant="plain" />
										</Tooltip>
									)}
									<Tooltip content="View exclusion tags">
										<Button
											onClick={() => onShowExclusionTagModal(automation)}
											icon={ProductRemoveIcon}
											disabled={!exclusionTags.length}
											variant="plain"></Button>
									</Tooltip>
									<Tooltip content="View messages">
										<Button onClick={() => onShowMessageNodeModal(automation)} icon={EmailIcon} variant="plain"></Button>
									</Tooltip>
								</Stack>
							];
						})}
					totals={['', '', getConversionTotal(), ...getStatTotals(), '']}
					hideScrollIndicator
					showTotalsInFooter
					fixedFirstColumns={2}
				/>
			</SlimPolarisTableButtons>
			<Modal
				title={`Exclusion tags - ${selectedAutomation?.name}`}
				open={showExclusionTagModal}
				onClose={onHideExclusionTagModal}
				secondaryActions={{ content: 'Close', onAction: onHideExclusionTagModal }}>
				<Modal.Section>
					<Stack spacing="tight">
						{selectedAutomation?.exclusionTags.map(tag => (
							<Tag>{tag}</Tag>
						))}
					</Stack>
				</Modal.Section>
			</Modal>
			<Modal
				title={`Messages - ${selectedAutomation?.name}`}
				open={showMessageNodeModal}
				onClose={onHideMessageNodeModal}
				secondaryActions={{ content: 'Close', onAction: onHideMessageNodeModal }}
				loading={isLoadingMessages}>
				<Modal.Section>
					<Stack spacing="tight">{renderMessageNodes()}</Stack>
				</Modal.Section>
			</Modal>
		</Card>
	);
}
