import { Banner, Button, Modal, Tooltip } from '@shopify/polaris';
import { ViewIcon } from '@shopify/polaris-icons';
import { useState } from 'react';
import styled from 'styled-components';
import { useProgramMessageScreenshot } from '../../hooks/marketingplatform/useScreenshots';

export type ProgramMessagePreviewActionProps = {
	accountId: number;
	programId: number;
	programMessageId: number;
};

export default function ProgramMessagePreviewAction({ accountId, programId, programMessageId }: ProgramMessagePreviewActionProps) {
	const [showPreview, setShowPreview] = useState(false);
	const { screenshotUrl, isLoading } = useProgramMessageScreenshot(accountId, programId, programMessageId, showPreview);

	return (
		<Tooltip content="Preview automation message" persistOnClick={false}>
			<Button icon={ViewIcon} onClick={() => setShowPreview(true)} variant="plain" />
			<Modal
				title="Preview"
				open={showPreview}
				onClose={() => setShowPreview(false)}
				loading={isLoading}
				primaryAction={{ content: 'Close', onAction: () => setShowPreview(false) }}>
				<Modal.Section>
					{!isLoading && !screenshotUrl && (
						<Banner tone="critical">
							<p>Unable to load screenshot.</p>
						</Banner>
					)}
					{screenshotUrl && <ImagePreview alt="Preview" src={screenshotUrl} />}
				</Modal.Section>
			</Modal>
		</Tooltip>
	);
}

const ImagePreview = styled.img`
	width: 100%;
	border: 0;
`;
