import { queryKey } from '@segunosoftware/equinox';
import { useQuery } from '@tanstack/react-query';
import { MARKETING_PLATFORM_SCREENSHOTS_NEWSLETTER, MARKETING_PLATFORM_SCREENSHOTS_PROGRAM_MESSAGE } from '../query-keys';
import type { Get } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

type ScreenshotUrl = {
	url: string;
};

export function useNewsletterScreenshot(accountId: number, newsletterId: number, enabled: boolean = true) {
	const { get } = useAuthenticatedFetch() as Get<ScreenshotUrl>;
	const { data, isFetching } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_SCREENSHOTS_NEWSLETTER, accountId, newsletterId),
		queryFn: () => get(`/marketing-platform-accounts/${accountId}/campaigns/${newsletterId}/preview`),
		enabled
	});

	return {
		screenshotUrl: data?.url,
		isLoading: isFetching
	};
}

export function useProgramMessageScreenshot(accountId: number, programId: number, programMessageId: number, enabled: boolean = true) {
	const { get } = useAuthenticatedFetch() as Get<ScreenshotUrl>;
	const { data, isFetching } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_SCREENSHOTS_PROGRAM_MESSAGE, accountId, programId, programMessageId),
		queryFn: () => get(`/marketing-platform-accounts/${accountId}/programs/${programId}/program-messages/${programMessageId}/preview`),
		enabled
	});

	return {
		screenshotUrl: data?.url,
		isLoading: isFetching
	};
}
